function getTimeRemaining(endtime){
  const t = endtime - moskow()

  let obj = {
    // days: t / (1000 * 60 * 60 * 24) | 0
    hours: t / (1000 * 60 * 60) % 24 | 0,
    minutes: t / 1000 / 60 % 60 | 0,
    seconds: t / 1000 % 60 | 0
  }

  for (let key in obj)
    obj[key] = ('0' + obj[key]).slice(-2)
    return obj
}
    
function moskow () {
  const offset = 3
  var d = new Date();
  d.setTime((new Date().getTime()) + (d.getTimezoneOffset() * 60 * 1000) + (1000 * 60 * 60 * offset));
  return d
}

const dayEnd = moskow()
dayEnd.setHours(23, 59, 59, 999)

let h = document.querySelector(".sale-h");
let m = document.querySelector(".sale-m");
let s = document.querySelector(".sale-s");

setInterval(() => {
  const result = getTimeRemaining(dayEnd);

  h.textContent = result.hours;
  m.textContent = result.minutes;
  s.textContent = result.seconds;
}, 1000)

const getSale = function() {
  return localStorage.getItem("time");
}
  
const setSale = function() {
  localStorage.setItem("time", Date.now());
}
  
let startTime = getSale();
let endTime = Date.now();
let timer = 300000; // 5min 300000
let resaultTime = (endTime - startTime) > timer;

if(getSale() == null || resaultTime) {
  setSale();
}
// open sale modal
let salemodal = document.querySelector('#salemodal');

if(window.screen.availWidth >= 768 && resaultTime) {
  setTimeout(() => { salemodal.style.display = 'block'; }, 1000);
}

let modalSaleClose = document.querySelector("#salemodal span.close");
modalSaleClose.addEventListener("click", () => { salemodal.style.display = "none"; });

let modalSaleCloseBtn = document.querySelector("button.button.sale-close");
modalSaleCloseBtn.addEventListener("click", () => { salemodal.style.display = "none"; });

// Заказать звонок
function submitSaleCC(params) {
  let str = window.atob("aHR0cHM6Ly9vb2NjbWFpbC5ydS9zZW5kP3Rva2VuPXZqOTgzNGhnMmhmNzg5aDIzNGZoNHVmaDM3OGZoMw==");

  fetch(`${str}`, {
    credentials: 'same-origin',
    mode:'no-cors',
    method: 'POST',

    headers: {
    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
    },

    body: `name=${params.name}&phone=${params.phone}`
  })
  .catch(error => console.log(error))
}

let modalFormSaleCC = document.querySelector('#call-form');
modalFormSaleCC.addEventListener('submit', (e) => submitFormSaleCC(e));
  
function submitFormSaleCC(e) {
  e.preventDefault();

  let name = modalFormSaleCC[0].value;
  let phone = modalFormSaleCC[1].value;

  submitSaleCC({ name: name, phone: phone});

  modalFormSaleCC[0].value = "";
  modalFormSaleCC[1].value = "";

  salemodal.style.display = "none";

  alert( "Успешно отправлено!" );
}
